'use strict'

const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');
const CONSTANTS = require('../constants/constants');


class CheckoutAnalyticsBuilder extends BaseAnalyticsBuilder {

    /**
     * CONSTRUCTOR
     * @param step -- Current checkout step. Use this argument to avoid event overlapping
     */
    constructor(step) {
        super();
        this.step = step;
        this.checkoutViewEventUrl = $('body .js-ga4-dataset').data('viewevent');
    }

    /**
     * This function will attach analytics events to current page
     */
    attachAnalytics() {
        //Attach base events
        super.attachAnalytics();

        //Attach specific events
        pushCheckoutViewEventData(this.step, this.checkoutViewEventUrl, true)

    }

}


function pushCheckoutViewEventData(step, checkoutViewEventUrl, render) {

    if(typeof dataLayer != 'undefined'){
        
        if(render){
            let exists = checkIfexists(CONSTANTS.EVENT_CONSTANTS.CHECKOUT.STEPS[step]) 
            if(exists) return;
        }
    
        //STEP 1 -- Get checkout event data using AJAX call
        $.ajax({
            url: checkoutViewEventUrl,
            type: 'GET',
            async: false,
            success: function success(ajaxData) {
                if (!ajaxData || !ajaxData.eventData)
                    return false;
    
                //STEP 2 -- Get response and push correct view event name
                var checkoutData = {
                    event: CONSTANTS.EVENT_CONSTANTS.CHECKOUT.STEPS[step],
                    ecommerce: ajaxData.eventData
                };
    
                dataLayer.push(checkoutData);
    
            }
        });
    }
}


/**
 * Function that serializes a checkout form, then adds analytics information if form BE validation is OK
 * @param event
 * @returns {boolean}
 */
function submitCheckoutForm(event) {
    if(typeof dataLayer != 'undefined'){
        
        event.preventDefault();
    
        const util = require('../util');
        var currentForm = $(this);
        if (!currentForm) {
            console.error('Could not send the form: checkout error');
            return false;
        }
    
        //STEP 0 -- Submit form action
        $.ajax({
            type: 'POST',
            url: util.ajaxUrl(currentForm.attr('action')) + '&action=' + event.data.action,
            data: currentForm.serialize(),
            success: function (data) {
                //STEP 1: Check data existence
                if (!data) {
                    console.error('Checkout update analytics failed');
                    return;
                }
    
                //STEP 2 -- Push data to analytics
                var currentEvent = {
                    event: event.data.eventName,
                    ecommerce: data.analyticsData
                }
                dataLayer.push(currentEvent);
    
                //STEP 3 -- Redirect to final location
                location.href = data.redirectUrl;
            },
        })
    }
}

function checkIfexists(eventName){

    let res = false;
    let data = window.dataLayer
    data.forEach(event => {
        if(event.event && event.event === eventName){
            res = true;
        } 
    });

    return res;
}



module.exports = CheckoutAnalyticsBuilder;
