'use strict'

const CONSTANTS = require('../constants/constants');

/**
 * Handles click on express checkout
 */
function clickPayPalExpress(event){

    if(typeof dataLayer != 'undefined'){
        
        // check if evt already exist
        window.dataLayer.forEach(el => {
            if (el.event == 'fast_checkout') return false;
        });
    
        //STEP 1 -- Get redirect url
        var analyticsUrl = $('.js-ga4-dataset').data("paypalanalytics");
        if (!analyticsUrl)
            return false;
    
        //STEP 2 -- Get analytics data
        $.ajax({
            type: 'GET',
            url: analyticsUrl,
            success: function (data) {
                //STEP 3: Check data existence
                if (!data || !data.eventData){
                    console.error('PayPal express analytics failed');
                    return;
                }
    
                //STEP 4: Build and push data to analytics
                dataLayer.push({
                    event : CONSTANTS.EVENT_LIST_NAME.EXPRESS_CHECKOUT,
                    ecommerce : data.eventData
                });
            },
        });
    }
}

module.exports = {
    clickPayPalExpress : clickPayPalExpress
}
