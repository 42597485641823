'use strict'

const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');

const CONSTANTS = require('../constants/constants');

class HomeAnalyticsBuilder extends BaseAnalyticsBuilder{

  constructor() {
    super();
    //Get all page elements who are useful for Google Analytics
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    //Attach base events
    super.attachAnalytics();

    //Attach all detail page triggers
    this.attachSpecificTriggers();

    //Attach intersection observer
    this.attachObserver();
  }

  /**
   * This function exposes triggers to element inside current page
   */
  attachSpecificTriggers(){
    document.querySelectorAll('.js-hero-slider .slick-track .slick-slide').forEach(function (element) {
      element.addEventListener('click', slideInteract, true);
    });

    this.setupCtaEventListeners();

    this.checkQrCode();
  }

  /**
   * Method to attach intersection observer to analytics elements
   * @returns {boolean}
   */
  attachObserver(){
    //STEP 1 -- Get carousel elements;
    const carouselElements = document.querySelectorAll('.js-analytics-element');
    if(!carouselElements){
      console.error('Homepage carousel has no elements');
      return false;
    }

    const observerOptions = {
      root: null, //Use viewport as view
      rootMargin: '0px', //Do not add extra margin
      threshold: 0.5, //Trigger view event if target element is at least 50% inside viewport
    };

    //STEP 2 -- Attach intersection observer
    // console.info('Observer setup completed');
    const observer = new IntersectionObserver(intersectionCallback, observerOptions);
    carouselElements.forEach(element => {
      observer.observe(element);
    });
  }

  /**
   * Method to attach intersection observer to the modal for tracking its open
   */
  attachModalObserver() {
    // STEP 1 -- Get the modal element
    const modal = document.querySelector('#modal-qr-code-app-redirect-id');
    if (!modal) {
      console.warn('Modal element not found');
      return false;
    }

    // STEP 2 -- Define observer options
    const observerOptions = {
      root: null, //Use the viewport as the view
      rootMargin: '0px', //Do not add extra margin
      threshold: 0.5 //Trigger view event if target element is at least 50% inside viewport
    };

    // STEP 3 -- Attach intersection observer with the custom callback
    const observer = new IntersectionObserver(modalIntersectionCallback, observerOptions);

    // STEP 4 -- Start observing the modal
    observer.observe(modal);
  }

  /**
   * Sets up event listeners for CTA elements, including modal open/close/click interactions.
   * @returns {void}
   */
  setupCtaEventListeners() {
    this.attachModalObserver();

    $('#modal-qr-code-app-redirect-id').on('click', function () {
      handleCtaInteraction(CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.CLICK);
    });

    $('#close-qr-code-app-redirect-id').on('click', function () {
      handleCtaInteraction(CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.CLOSE);
    });
  }

  /**
   * This function sends events based on 'qrCodeRedirect', 'isFooter', and 'isHero' URL parameters.
   * @returns {void}
   */
  checkQrCode() {

    if(typeof dataLayer != 'undefined'){
        
      const params = new URLSearchParams(window.location.search);
  
      //STEP 1: If QR_CODE_REDIRECT could not be found return false
      if (!params.get(CONSTANTS.URL_PARAMS.QR_CODE_REDIRECT))
        return;
  
      //STEP 2: Check event type
      let eventType = '';
      if (params.get(CONSTANTS.URL_PARAMS.FOOTER_PARAM.NAME))
        eventType = CONSTANTS.URL_PARAMS.FOOTER_PARAM.VALUE;
      else if (params.get(CONSTANTS.URL_PARAMS.HERO_PARAM.NAME))
        eventType = CONSTANTS.URL_PARAMS.HERO_PARAM.VALUE;
  
      //STEP 3: Push event
      if (eventType) {
        dataLayer.push({
          event: CONSTANTS.GENERIC_EVENTS.EVENT_NAME,
          event_category: CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.QR_CODE,
          event_action: eventType
        });
      }
    }

  }

}


/**
 * Event and private functions
 */


/**
 * Callback for elements that are intersecting current view space
 * @param entries
 * @param observer
 */
function intersectionCallback(entries, observer) {
  entries.forEach(entry => {
    //STEP 1 -- Check if current element is inside view space
    if (entry.isIntersecting){
      //STEP 2 -- Push analytics
      pushAssetAnalytics(entry.target, CONSTANTS.EVENT_LIST_NAME.VIEW_ASSET_IMAGE);

      //STEP 3 -- Remove observer: this GA4 event is one shot
      observer.unobserve(entry.target);
    }
  });
}

/**
 * Callback for the modal that is intersecting the current view space
 * @param entries
 * @param observer
 */
function modalIntersectionCallback(entries, observer) {
  entries.forEach(entry => {
    //STEP 1 -- Check if current element is inside view space
    if (entry.isIntersecting) {
      //STEP 2 -- Push analytics
      handleCtaInteraction(CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.OPEN);

      //STEP 3 -- Remove observer: this GA4 event is one shot
      observer.unobserve(entry.target);
    }
  });
}

/**
 * Sends CTA interaction events to the dataLayer for analytics tracking.
 * The actionType parameter to specify the type of interaction (open, close and click).
 * @returns {void}
 */
function handleCtaInteraction(actionType) {
  if(typeof dataLayer != 'undefined'){
        
    dataLayer.push({
      event: CONSTANTS.GENERIC_EVENTS.EVENT_NAME,
      event_category: CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.CTA,
      event_action: actionType
    });
  }
}

/**
 * Homepage slide interaction event
 */
function slideInteract(event){
  pushAssetAnalytics(event.currentTarget, CONSTANTS.EVENT_LIST_NAME.SELECT_ASSET_IMAGE);
}


/**
 * Push event for content asset interaction
 * @param target
 * @param type
 */
function pushAssetAnalytics(target, type){
  if(typeof dataLayer != 'undefined'){
        
    //STEP 1 -- Get element data
    var targetButton = document.querySelector('.js-analytics-address');
    var link = target.querySelector('.cc-module-link');
    var buttonType = '';
    if(targetButton && link){
      var locale = targetButton.dataset.locale.toLowerCase().replace('_', '-');
      buttonType = '/' + locale + link.href.toLowerCase().split(locale)[1];
    }
  
    //STEP 2 -- Push analytics
    dataLayer.push({
      event: type,
      ecommerce: {
        creative_name: target.dataset.contentasset,
        creative_slot:  target.dataset.position,
        promotion_id: buttonType
      }
    });
  }
}


module.exports = HomeAnalyticsBuilder;
