'use strict'

const EventHelper = require('./eventHelper');

/**
 * Method to attach intersection observer to analytics elements from carousels
 * @returns {boolean}
 */
function attachObserverCarousel(){
    //STEP 1: Get carousel and check if carousel elements exist
    const carouselElements = document.querySelectorAll('.slick-carousel-wrapper:not(.observed), .special-products:not(.observed), .js-wishlist-carousel:not(.observed)');
    if (carouselElements.length === 0) {
        console.error('Carousel not found');
        return false;
    }

    const observerOptions = {
        root: null, //Use viewport as view
        rootMargin: '0px', //Do not add extra margin
        threshold: 0.5, //Trigger view event if target element is at least 50% inside viewport
    };

    //STEP 2: Attach intersection observer
    console.info('Observer setup completed');
    const observer = new IntersectionObserver(intersectionCallback, observerOptions);
    carouselElements.forEach(element => {
        observer.observe(element);
        element.classList.add('observed');
    });
}

/**
 * PRIVATE FUNCTIONS
 */

/**
 * Callback for elements that are intersecting current view space
 * @param entries
 * @param observer
 */
function intersectionCallback(entries, observer) {
    entries.forEach(entry => {
        //STEP 1: Check if current element is inside view space
        if (entry.isIntersecting){
            //STEP 2: Push analytics
            getCarouselProduct(entry.target);

            //STEP 3: Remove observer
            observer.unobserve(entry.target);
        }
    });
}

/**
 * Retrieves product IDs from a carousel element and sends an AJAX request for analytics.
 * @param {HTMLElement} carouselElement - The carousel element from which product IDs are extracted.
 */
function getCarouselProduct(carouselElement) {
    //STEP 0: Look for core data inside grid and extract 'data-carousel-context' from 'product-tile-a'
    var grid = $('body');
    var analyticsUrl = grid.data('contexteventcarousel');
    if(!analyticsUrl || analyticsUrl === '')
        return false;

    var carouselContext = $(carouselElement)
        .find('.product-tile-a')
        .data('carousel-context');

    // Step 1: Extract product IDs from the carousel tile product
    var ids = $(carouselElement)
        .find('.product-tile-v2')
        .map((_,elem) => {
            if($(elem).attr('id'))
                return $(elem).data('itemid');
        }).get().join(',');

    // Step 2: Send AJAX GET request with the product IDs for analytics
    $.ajax({
        type: 'GET',
        url: analyticsUrl + carouselContext +'&productString=' + ids,
        success: function (data) {
            //STEP 3: Check data existence
            if (!data || !data.eventData){
                console.error('List view analytics failed');
                return;
            }

            //STEP 4: Build and push data to analytics
            EventHelper.pushViewListEvent(data);
        },
    });
}

module.exports = {
    attachObserverCarousel : attachObserverCarousel
}
