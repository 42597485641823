'use strict'

const GA4AnalyticsFactory = require('./factories/ga4AnalyticsFactory');

//STEP 1 -- Call analytics factory
// console.info('Starting GA4 Analytics');
// var pageID = $('body').data('analytics');
var pageID = $('body .js-ga4-dataset').data('analytics');
var step = $('body .js-ga4-dataset').data('step');
var analyticsFactory = new GA4AnalyticsFactory(pageID, step);

//STEP 2 -- Get concrete builder
var concreteBuilder = analyticsFactory.getConcreteAnalyticsBuilder();
if (!concreteBuilder)
    console.error('Impossible to attach GA4 for page: ' + pageID);

//STEP 3 -- Call method to attach events
concreteBuilder.attachAnalytics();