'use strict'

const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');
const CONSTANTS = require('../constants/constants');

class AccountAnalyticsBuilder extends BaseAnalyticsBuilder{

  constructor() {
    super();
    //Get all page elements who are useful for Google Analytics
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    //Attach base events
    super.attachAnalytics();

    //Attach all return page triggers
    this.attachSpecificTriggers();
  }


  /**
   * This function exposes triggers to element inside current page
   */
  attachSpecificTriggers(){
    const NotificationEventHelper = require('../helpers/notificationEventHelper');

    // Click My Account notifications section
    $('.js-analytics-box-notices').on('click', function () {
      NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.MYACCOUNT_NOTICES, CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.NOTICES);
    });
  }

}

/**
 * Private functions
 */

function handleRegistrationEvent(event){
  if(typeof dataLayer != 'undefined'){
        
    event.preventDefault();
  
    const util = require('../util');
    var currentForm = $(this);
    if(!currentForm){
      console.error('Could not find the form: registration error');
      return false;
    }
  
    //STEP 0 -- Submit form action
    $.ajax({
      type: 'POST',
      url: util.ajaxUrl(currentForm.attr('action')) + '&action=' + event.data.action,
      data: currentForm.serialize(),
      success: function (data) {
        //STEP 1: Check data existence
        if (!data){
          console.error('Account registration analytics failed');
          return;
        }
  
        //STEP 2 -- Push data to analytics
        if(data.analyticsData)
          dataLayer.push(data.analyticsData);
  
      },
    })
  }
}

module.exports = AccountAnalyticsBuilder;
