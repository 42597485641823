'use strict'

const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');

const CONSTANTS = require('../constants/constants');

class ReturnAnalyticsBuilder extends BaseAnalyticsBuilder{

  constructor() {
    super();
    //Get all page elements who are useful for Google Analytics
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    //Attach base events
    super.attachAnalytics();

    //Attach all return page triggers
    this.attachSpecificTriggers();
  }


  /**
   * This function exposes triggers to element inside current page
   */
  attachSpecificTriggers(){
    $('.js-refund-action').on('click', viewReturnEvent);
  }

}

/**
 * Private functions
 */


/**
 * When page is loaded, check if the pages comes from a form send
 * @param event
 * @returns {boolean}
 */
function viewReturnEvent(){

  if(typeof dataLayer != 'undefined'){
        
    dataLayer.push({
      event : CONSTANTS.EVENT_LIST_NAME.CREATE_RETURN
    });
  }
}

module.exports = ReturnAnalyticsBuilder;
