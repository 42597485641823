'use strict';

// OBSERVABLE FOR CHANGE STAGE EVENTS
if(typeof dataLayer != 'undefined'){
    $(document).ready(function () {
    
        if (_DONE == true) {
            return;
        }
    
        let checkout = document.querySelector('#checkout-main');
    
        //ATTACH APP ANALYTICS EVENTS ON LOAD
        attachAppAnalyticsEvents();
    
        if (checkout) {
    
            let prevStage = $('#checkout-main').data('data-checkout-stage');
    
            // Create a MutationObserver to listen for changes to data-checkout-stage attribute
            var observer = new MutationObserver(function (mutations) {
    
                mutations.forEach(function (mutation) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'data-checkout-stage') {
    
                        const GA4AnalyticsFactory = require('./factories/ga4AnalyticsFactory');
    
                        //STEP 1 -- Call analytics factory
                        var datasetElement = $('body .js-ga4-dataset-checkout');
                        var pageID = datasetElement.data('analytics');
                        var step = datasetElement[0].dataset.step;
                        let attachUrl = datasetElement.data('attachurl');
                        let stage = mutation.target.getAttribute('data-checkout-stage');
                        var analyticsFactory = new GA4AnalyticsFactory(pageID, step);
    
                        //STEP 2 -- Get concrete builder
                        var concreteBuilder = analyticsFactory.getConcreteAnalyticsBuilder();
                        if (!concreteBuilder)
                            console.error('Impossible to attach GA4 for page: ' + pageID);
    
                        //STEP 3 -- Call method to attach events
                        //concreteBuilder.attachAnalytics();
    
                        //AJAX
                        $.ajax({
                            url: `${attachUrl}?source=ajax&page=Checkout-${stage}&stage=${stage}&step=${step}`,
                            type: 'GET',
                            success: function success(data) {
    
                                var dataLayerData = data.ContextData;
                                window.dataLayer = window.dataLayer || [];
                                dataLayer.forEach(function (item) {
                                    if (item.hasOwnProperty('login')) {
                                        item.pageType = dataLayerData.pageType;
                                    }
                                });
    
                                if (data.analyticsData) {
                                    let res = data.analyticsData
                                    datasetElement.attr('data-step', res.step);
                                    datasetElement.attr('data-viewevent', res.viewEventUrl);
                                    pushCheckoutViewEventData(res.step, res.viewEventUrl)
    
                                    //ATTACH APP ANALYTICS EVENTS
                                    if ($('.cc-userAppCheck').length > 0 && $('.cc-userAppCheck').hasClass('cc-userApp')) {
                                        attachAppAnalyticsEvents(step);
                                    }
                                }
                            },
                            error: function error(err) {
                                console.error('checkoutEvents.js error: ', err);
                            }
                        });
    
                        //STEP 4 -- Update step value
    
                    }
                });
            });
    
            // Configure the observer to watch for attribute changes
            var config = { attributes: true, attributeFilter: ['data-checkout-stage'] };
    
            // Start observing the body element
            observer.observe(document.querySelector('#checkout-main'), config);
        }
    
        _DONE = true;
    
    });
}

function pushCheckoutViewEventData(step, checkoutViewEventUrl) {

    if(typeof dataLayer != 'undefined'){
        if(step != 5){
            const CONSTANTS = require('./constants/constants');
            var orderNo = $('body .js-ga4-dataset-checkout').data('orderno');
        
            if (orderNo) {
                checkoutViewEventUrl += '&order=' + orderNo;
            }
        
            //STEP 1 -- Get checkout event data using AJAX call
            $.ajax({
                url: checkoutViewEventUrl,
                type: 'GET',
                async: false,
                success: function success(ajaxData) {
                    if (!ajaxData || !ajaxData.eventData)
                        return false;
        
                    //STEP 2 -- Get response and push correct view event name
                    var checkoutData = {
                        event: CONSTANTS.EVENT_CONSTANTS.CHECKOUT.STEPS[step],
                        ecommerce: ajaxData.eventData
                    };
        
                    // if (checkoutData.event === CONSTANTS.EVENT_CONSTANTS.CHECKOUT.STEPS[5]) {
                    //     sessionStorage.setItem('dataLayer', JSON.stringify(checkoutData));
                    //     return;
                    // } else {
                    //     dataLayer.push(checkoutData);
                    // }
                    dataLayer.push(checkoutData);

                }
            });
        }
    }

}


function attachAppAnalyticsEvents(step) {

    if ($('.cc-userAppCheck').length > 0 && $('.cc-userAppCheck').hasClass('cc-userApp')) {

        const CONSTANTS = require('../analytics/constants/constants');
        const FlutterHelper = require('../analytics/helpers/flutterHelper');
        const ArrayBuilder = require('../analytics/helpers/arrayBuilder');
        const CheckoutAnalytics = require('../analytics/concrete/checkoutAnalytics');



        let datasetElement = $('#loyaltyapp-customer-dataset');

        if (!step) {
            // var datasetStep = $('body .js-ga4-dataset-checkout');
            // step = datasetStep[0].dataset.step != 'null' ? datasetStep[0].dataset.step : setStepFromUrlStage();
            const stagesMapping = {
                'customer': 1,
                'shipping': 2,
                'billing': 3,
                'payment': 4
            }
            const urlParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlParams.entries());
            step = stagesMapping[params.stage];
        }

        let products = null;

        //Get Products
        let inputString = datasetElement.data('productsga');
        let productsArray = null;

        if(inputString){
            inputString = inputString.replace(/'/g, '"');
            inputString = "[" + inputString + "]";
            productsArray = JSON.parse(inputString);
            products = ArrayBuilder.getItemArray(productsArray);
        }

        if (step != null) {

            try {
                //CUSTOMER
                if (step == 1) {
                    let datasetElement = $('#loyaltyapp-customer-dataset');
                    let eventName = CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.STEPS.FIRST;
                    let eventBody = {
                        'ecommerce': {
                            'checkout': {
                                'actionField': {
                                    'step': step,
                                    'svn1': datasetElement.data('productlineitemsga'),
                                    'sv9': datasetElement.data('couponcodega')
                                },
                                'products': productsArray
                            }
                        }
                    }

                    let checkoutAnalytics = new CheckoutAnalytics(eventBody);
                    checkoutAnalytics.pushEvent();

                }

                //SHIPPING
                if (step == 2) {

                    let datasetElement = $('#loyaltyapp-customer-dataset');
                    let shipmentId = datasetElement[0].dataset.shipmentid
                    let eventName = CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.STEPS.SECOND;

                    let eventBody = {
                        'ecommerce': {
                            'checkout': {
                                'actionField': {
                                    'step': step,
                                    'svn1': datasetElement.data('productlineitemsga'),
                                    'sv9': datasetElement.data('couponcodega'),
                                    'sv10': shipmentId
                                },
                                'products': productsArray
                            }
                        }
                    }

                    let checkoutAnalytics = new CheckoutAnalytics(eventBody);
                    checkoutAnalytics.pushEvent();

                }

                //BILLING
                if (step == 3) {
                    let datasetElement = $('#loyaltyapp-customer-dataset');
                    let dataset = datasetElement[0].dataset;
                    let eventName = CONSTANTS.ANALYTICS_EVENTS.CHECKOUT.STEPS.THIRD;
                    let eventBody = {
                        'ecommerce': {
                            'checkout': {
                                'actionField': {
                                    'step': step,
                                    'svn1': dataset.productlineitemsga,
                                    'sv4': dataset.storeregion,
                                    'sv5': dataset.storeprovince,
                                    'sv6': dataset.storecity,
                                    'sv7': dataset.storename,
                                    'sv9': dataset.couponcodega,
                                    'sv10': dataset.shipto
                                },
                                'products': productsArray
                            }
                        }
                    }

                    let checkoutAnalytics = new CheckoutAnalytics(eventBody);
                    checkoutAnalytics.pushEvent();;

                }

            }
            catch (error) {
                console.error('Error: impossible to send event checkout');
            }
        }

    }

}

function checkIfexists(shipmentId) {

    let checkoutEvent;
    window.dataLayer.forEach(el => {
        if (el.event == 'checkout') checkoutEvent = el;
    });
    if (!checkoutEvent) return;

    if (checkoutEvent.ecommerce.checkout.actionField.sv10 == shipmentId) return true;

    return false;
}

function setStepFromUrlStage() {

    let page = $('.js-page');
    if (page.data('action') == 'Order-Confirm') return 5;

    const urlParams = new URLSearchParams(window.location.search);
    let stage = urlParams.get('stage');
    let res;

    switch (stage) {
        case 'customer':
            res = 1;
            break;
        case 'shipping':
            res = 2;
            break;
        case 'billing':
            res = 3;
            break;
        case 'payment':
            res = 4;
            break;
        case 'placeOrder':
            res = 5;
            break;

        default:
            break;
    }

    return res;
}
