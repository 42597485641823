'use strict'

const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');

class DefaultAnalyticsBuilder extends BaseAnalyticsBuilder{

  constructor() {
    super();
    //Get all page elements who are useful for Google Analytics
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    //Attach base events
    super.attachAnalytics();
  }

}

module.exports = DefaultAnalyticsBuilder;
