'use strict'

const CONSTANTS = require('../constants/constants');

/**
 * Sends CTA interaction events to the dataLayer for analytics tracking.
 * The actionType parameter to specify the type of interaction (eventCategory, actionType).
 * @returns {void}
 */
function handleCtaInteraction(eventCategory, actionType) {

    if(typeof dataLayer != 'undefined'){
        
        //STEP 1: Create the base object for dataLayer
        var eventObject = {
            event: CONSTANTS.GENERIC_EVENTS.EVENT_NAME,
            event_category: eventCategory
        };
    
        //STEP 2: Add the event_action property only if actionType is defined
        if (actionType) {
            eventObject.event_action = actionType;
        }
    
        //STEP 3: Send the object to dataLayer
        dataLayer.push(eventObject);
    }
}

module.exports = {
    handleCtaInteraction : handleCtaInteraction
}