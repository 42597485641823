'use strict'

const CONSTANTS = require('../constants/constants');
const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');

class WishlistAnalyticsBuilder extends BaseAnalyticsBuilder{

    constructor() {
        super();
    }

    /**
     * This function will attach analytics events to current page
     */
    attachAnalytics() {
        //Attach base events
        super.attachAnalytics();

        //Attach all detail page triggers
        this.attachSpecificTriggers();
    }


    /**
     * This function exposes triggers to element inside current page
     */
    attachSpecificTriggers(){
        $(document).on(CONSTANTS.EVENT_CONSTANTS.WISHLIST.EVENTS.VIEW_ITEMS, viewWishlist);
        //Trigger first wishlist view event
        $(document).trigger(CONSTANTS.EVENT_CONSTANTS.WISHLIST.EVENTS.VIEW_ITEMS);

        $(document).on(CONSTANTS.EVENT_CONSTANTS.WISHLIST.EVENTS.ADD_PRODUCT, {url : this.detailViewEventUrl}, addProduct);
    }
}

/**
 * PRIVATE FUNCTIONS
 */

/**
 * Remove product event function
 */
function addProduct(event, data) {

    if (typeof dataLayer != 'undefined') {

        let url = $('.js-ga4-dataset').data('addproducturl');

        //STEP 1: Update cart
        $.ajax({
            type: 'POST',
            url: url + '?detailaddtocart=true',
            success: function (data) {
                //STEP 1: Check data existence
                if (!data.analyticsData) {
                    console.error('PLP add to card failed');
                    return;
                }

                //STEP 2: Build and push data to analytics
                dataLayer.push(data.analyticsData);
            },
        });
    }
}

function viewWishlist(){
    //STEP 0 -- Look for core data inside grid
    var grid = $('.wishlist-wrap');
    var analyticsUrl = grid.data('analytics');
    if(!analyticsUrl || analyticsUrl === '')
        return false;

    //STEP 1 -- Get all items
    var productString = '';
    var products = $('.sku');
    products.each(function(){
        var value = $(this).find('.value');
        if (value.length < 1)
            return;

        let check = value[0].innerText.trim()
        productString += value[0].innerText.trim() + ',';
    });

    //STEP 2 -- Remove last comma
    if(!productString){
        console.error('No data for analytics wishlist');
        return false;
    }
    else
        productString = productString.slice(0, -1); //Remove last ','

    //STEP 3 -- Call BE for Analytics data
    $.ajax({
        type: 'GET',
        url: analyticsUrl +'&productString=' + productString,
        success: function (data) {
            //STEP 4: Check data existence
            if (!data || !data.eventData){
                console.error('List view wishlist failed');
                return;
            }

            //STEP 5: Build and push data to analytics
            const EventHelper = require('../helpers/eventHelper');
            EventHelper.pushViewListEvent(data);
        },
    });
}

module.exports = WishlistAnalyticsBuilder;
