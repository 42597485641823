'use strict'

const CONSTANTS = require('../constants/constants');
const ReturnAnalyticsBuilder = require('../concrete/returnAnalyticsBuilder');
const HomeAnalyticsBuilder = require('../concrete/homeAnalyticsBuilder');
const DefaultAnalyticsBuilder = require('../concrete/defaultAnalyticsBuilder');
const ListingAnalyticsBuilder = require('../concrete/listingAnalyticsBuilder');
const DetailsAnalyticsBuilder = require('../concrete/detailsAnalyticsBuilder');
const CartAnalyticsBuilder = require('../concrete/cartAnalyticsBuilder');
const CheckoutAnalyticsBuilder = require('../concrete/checkoutAnalyticsBuilder');
const AccountAnalyticsBuilder = require('../concrete/accountAnalyticsBuilder');
const WishlistAnalyticsBuilder = require('../concrete/wishlistAnalyticsBuilder');

class AnalyticsFactory{

    /**
     * Constructor for GA4 Analytics Factory
     * @param pageID -- Current page ID, used to get a concrete Analytics Builder
     * @param step -- Step associated to GA4 analytics. Useful to avoid 'class explosion' effect
     */
    constructor(pageID, step){
        this.pageID = pageID;
        this.step = step;
    }

    /**
     * Function that retrieves an analytics builder
     * @returns {ListingAnalyticsBuilder|DefaultAnalyticsBuilder|HomeAnalyticsBuilder|CartAnalyticsBuilder|CheckoutAnalyticsBuilder
     * |DetailsAnalyticsBuilder|ReturnAnalyticsBuilder|AccountAnalyticsBuilder|WishlistAnalyticsBuilder}
     */
    getConcreteAnalyticsBuilder(){
        switch(this.pageID){
            case CONSTANTS.EVENT_CONSTANTS.HOME.ID:
                return new HomeAnalyticsBuilder();
            case CONSTANTS.EVENT_CONSTANTS.RETURN.ID:
                return new ReturnAnalyticsBuilder();
            case CONSTANTS.EVENT_CONSTANTS.PLP.ID:
                return new ListingAnalyticsBuilder();
            case CONSTANTS.EVENT_CONSTANTS.PDP.ID:
                return new DetailsAnalyticsBuilder();
            case CONSTANTS.EVENT_CONSTANTS.CART.ID:
                return new CartAnalyticsBuilder();
            case CONSTANTS.EVENT_CONSTANTS.ACCOUNT.ID:
                return new AccountAnalyticsBuilder();
            case CONSTANTS.EVENT_CONSTANTS.CHECKOUT.ID:
                return new CheckoutAnalyticsBuilder(this.step);
            case CONSTANTS.EVENT_CONSTANTS.WISHLIST.ID:
                return new WishlistAnalyticsBuilder();
            default:
                return new DefaultAnalyticsBuilder();
        }
    }

}

module.exports = AnalyticsFactory;
